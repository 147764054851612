@tailwind base;
@tailwind components;
@tailwind utilities;

.table th:first-child {
  position: relative; /* Responsive fix for first table cols*/
}

.markdown > h1 {
  font-size: 2em;
}

.markdown > h2 {
  font-size: 1.8em;
}

.markdown > h3 {
  font-size: 1.5em;
}

.markdown > ul {
  list-style: disc;
  list-style-position: inside;
}

.markdown > * + *	{
  margin-top: 0.5rem; /* 8px */
}
